import * as React from "react"
import HeaderComponent from "../../Components/HeaderComponent";
import Layout from '../../Components/Layout';
import Seo from "../../Components/Seo";
import Contact from '../../modules/Contact'

const index = () => {
    return (
        <>
            
            <Seo title="Contact | Webbermill"/>
            <Layout className="contact">
                <title>Contact Page</title>
                <HeaderComponent hasProduct/>
                <Contact />
            </Layout>
        </>
    );
};

export default index;
