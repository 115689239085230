import * as React from "react"
import { CheckIconSVG, UserCheckIconSVG } from "../../Components/SVGs";
import contactContent from "../../../content/contactPageContent.json";
import { Form } from "react-bootstrap";
import { CustomInput, CustomSelect2, CustomTextarea } from "../../Components/CustomInput";
import { CustomButton } from "../../Components/CustomButton";
import { Link } from "gatsby";
import { FooterComponent } from "../../Components/FooterComponent"
import { LEGAL_PAGE } from "../../constant/routes";
import { useForm } from '@formspree/react';

import SuccessCard from "../../Components/SuccessCard";
import LoaderCard from "../../Components/Loader";


const ContactIndex = () => {
    // console.log(contactContent)
      const [state, handleSubmit] = useForm("mvoldoyr");
     
     if (state.succeeded) {
         return <SuccessCard />
     }


     const FormContent = () => (
          <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <CustomInput label={contactContent.formLabels[0]} name="First Name" hasRequired/>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <CustomInput label={contactContent.formLabels[1]} name="Last Name" hasRequired/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBlock: 25}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <CustomInput type="email" label={contactContent.formLabels[2]} name="Email" hasRequired/>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <CustomInput type="tel" label={contactContent.formLabels[3]} name="Phone Number" hasRequired/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            {/* <CustomInput label={contactContent.formLabels[4]} /> */}
                                            <CustomSelect2 label={contactContent.formLabels[4]} name={contactContent.formLabels[4]} hasRequired>
                                                <option></option>
                                                <option value="Google">Google</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Recommodation">Recommendation</option>
                                                <option value="Television">Television</option>
                                                <option value="Newletter">Newletter</option>
                                                <option value="Online Ads">Online Ads</option>
                                            </CustomSelect2>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <CustomSelect2 label={contactContent.formLabels[5]} name={contactContent.formLabels[5]} hasRequired>
                                                <option></option>
                                                <option value="Sales">Sales</option>
                                                <option value="Support">Support</option>
                                                <option value="General">General</option>
                                            </CustomSelect2>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBlock: 25}}>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <CustomTextarea placeholder="Write your message..." label={contactContent.formLabels[6]} name={contactContent.formLabels[6]}/>
                                        </div>

                                    </div>
                                    <div className="row form_text">
                                        <Form.Text id="terms and conditions">
                                            By signing up, you are agreeing to our <Link to={LEGAL_PAGE}>terms</Link> and acknowledge reading our <Link to={LEGAL_PAGE}>privacy notice</Link>.
                                        </Form.Text>
                                    </div>
                                    <div className="d-flex mt-5 c0l-sm-12">
                                        <div className=" mx-auto primaryBtn">
                                            <CustomButton type="submit" text={contactContent.buttonText} />
                                        </div>
                                    </div>
                                </Form>
     )

    return (
        <>
            <div className="contact">
                <section className="contact__content container">
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-5 mx-auto contact__content-text">
                            <h5>{contactContent.homePageTexts[0]}</h5>
                            <p>{contactContent.homePageTexts[1]}</p>
                        </div>
                    </div>
                    <div className="contact__content-capsule">
                        <div className="contact__content-capsuleLeft d-flex">
                            <div className="mr-3 capsule-img">
                                <UserCheckIconSVG />
                            </div>
                            <div className="capsule-text">
                                <h5>{contactContent.capsuleContent.letfContent[0]}</h5>
                                <p><span>Phone:</span> {contactContent.capsuleContent.letfContent[1]} </p>
                                <p><span>Email:</span> {contactContent.capsuleContent.letfContent[2]}</p>
                            </div>
                        </div>
                        <div className="contact__content-capsuleRight d-flex">
                            <div className="mr-3 capsule-img">
                                <CheckIconSVG />
                            </div>
                            <div className="capsule-text">
                                <h5>{contactContent.capsuleContent.rightContent[0]}</h5>
                                <p><span>Phone:</span> {contactContent.capsuleContent.rightContent[1]} </p>
                                <p><span>Email:</span> {contactContent.capsuleContent.rightContent[2]}</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact__content-text2">
                        <h5>{contactContent.homePageTexts[2]}</h5>
                        <p>{contactContent.homePageTexts[3]}</p>
                    </div>
                    <div className="contact__form">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-7 mx-auto">
                                {state.submitting ? (<LoaderCard />) : (<FormContent />)}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterComponent />
        </>
    );
};

export default ContactIndex;
